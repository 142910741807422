import React, { useState } from "react";
import Login from "./components/Login";
import SignUp from "./components/SignUp";
import "./styles/Entry.css";

import { toast } from "react-toastify";

function Entry() {
  const [entryState, setEntryState] = useState(false);

  return (
    <div className="container">
      <div className="inner-div">
        {entryState ? (
          <SignUp changeToLogin={() => setEntryState(false)} />
        ) : (
          <Login changeToSignUp={() => setEntryState(true)} />
        )}
      </div>
    </div>
  );
}

export default Entry;
