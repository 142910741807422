import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Menu from "./Menu";
import Administrator from "./components/Administrator";
import PortOwners from "./components/PortOwners";
import Visitors from "./components/Visitors";
import axios from "axios";
import "./styles/Home.css";

function Home() {
  const location = useLocation();
  const [isOwner, setIsOwner] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  const view = location.state?.view;
  const { name, username, email } = location.state || {};

  useEffect(() => {
    const checkAdmin = async () => {
      try {
        const adminResponse = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}users/admin`,
          {
            headers: { authToken: localStorage.getItem("AuthToken") },
          }
        );
        setIsAdmin(adminResponse.data.isAdmin);
      } catch (error) {
        console.error("Errore nel controllo admin:", error);
      }
    };

    const checkOwner = async () => {
      try {
        const ownerResponse = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}users/isOwner`,
          {
            headers: { authToken: localStorage.getItem("AuthToken") },
          }
        );
        setIsOwner(ownerResponse.data.isOwner);
      } catch (error) {
        console.error("Errore nel controllo proprietà:", error);
      }
    };

    checkAdmin();
    checkOwner();
  }, []);

  if (!view) {
    return <p>Error: No view provided. Please login again.</p>;
  }

  let ChildComponent;
  switch (view) {
    case "administrator":
      ChildComponent = Visitors;
      break;
    case "portOwners":
      ChildComponent = Visitors;
      break;
    case "visitors":
      ChildComponent = Visitors;
      break;
    default:
      ChildComponent = () => <p>Error: Invalid view.</p>;
  }

  return (
    <div className="container-home">
      <Menu isOwner={isOwner} isAdmin={isAdmin} />
      <ChildComponent name={name} username={username} email={email} />
    </div>
  );
}

export default Home;
