import React, { useState, useEffect } from "react";
import "../src/pages/styles/CookieBanner.css";

function CookieBanner() {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem("cookieConsent");
    if (!consent) {
      setShowBanner(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("cookieConsent", "true");
    setShowBanner(false);
    console.log("Cookies accepted");
  };

  const handleDecline = () => {
    localStorage.setItem("cookieConsent", "false");
    setShowBanner(false);
    console.log("Cookies declined");
  };

  if (!showBanner) return null;

  return (
    <div className="cookie-banner-container">
      <p className="cookie-banner-text">
        This website uses cookies to enhance your browsing experience.{" "}
        <a href="/privacy-policy" className="cookie-banner-link">
          Learn more
        </a>
      </p>
      <div className="cookie-banner-button-container">
        <button className="cookie-banner-button" onClick={handleAccept}>
          Accept
        </button>
        <button
          className="cookie-banner-button-decline"
          onClick={handleDecline}
        >
          Decline
        </button>
      </div>
    </div>
  );
}

export default CookieBanner;
