import React, { useEffect, useState } from "react";
import axios from "axios";
import Select from "react-select";
import "../styles/AddPort.css";

const formatServiceName = (name) => {
  return name
    .replace(/([A-Z])/g, " $1") // Aggiunge uno spazio prima di ogni lettera maiuscola
    .replace(/^./, (str) => str.toUpperCase()); // Converte la prima lettera in maiuscolo
};
function ShowPorts({ setSelectedServices, setFilters }) {
  const [formData, setFormData] = useState({
    carburante: false,
    acqua: false,
    energiaElettrica: false,
    gru: false,
    serviziIgienici: false,
    docce: false,
    serviziAntincendio: false,
    riparazioneMotori: false,
    riparazioneElettriche: false,
    serviziMeteo: false,
    ormeggiatori: false,
    guardiania: false,
    wifi: false,
    bar: false,
    ristorante: false,
    pizzeria: false,
    market: false,
    noleggioAuto: false,
    noleggioBici: false,
    scivolo: false,
  });
  const [dropdownData, setDropdownData] = useState({
    cities: [],
    provinces: [],
    categories: [],
  });

  useEffect(() => {
    const fetchDropdownData = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_SERVER_URL + "ports",
          {
            headers: {
              authToken: localStorage.getItem("AuthToken"),
            },
          }
        );
        const ports = response.data;

        const cities = [...new Set(ports.map((port) => port.città))];
        const provinces = [...new Set(ports.map((port) => port.provincia))];
        const categories = [...new Set(ports.map((port) => port.categoria))];
        setDropdownData({ cities, provinces, categories });
      } catch (error) {
        console.error("Errore nel recupero dei dati per i filtri:", error);
      }
    };

    fetchDropdownData();
  }, []);
  const handleChange = (e) => {
    const { name, checked } = e.target;
    const updatedFormData = { ...formData, [name]: checked };
    setFormData(updatedFormData);

    const selectedServices = Object.keys(updatedFormData).filter(
      (key) => updatedFormData[key]
    );
    setSelectedServices(selectedServices);
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  };

  const sortedFields = Object.keys(formData).sort((a, b) =>
    formatServiceName(a).localeCompare(formatServiceName(b))
  );

  return (
    <div className="addPort-container">
      <div className="filtri-sx">
        <div className="input-title">Filtri</div>
        <div className="middle-top-show">
          <div className="filtri-select">
            <select name="category" onChange={handleFilterChange}>
              <option value="">Categoria</option>
              {dropdownData.categories.map((category) => (
                <option key={category} value={category}>
                  {category}
                </option>
              ))}
            </select>
          </div>
          <div className="filtri-select">
            <select name="city" onChange={handleFilterChange}>
              <option value="">Città</option>
              {dropdownData.cities.map((city) => (
                <option key={city} value={city}>
                  {city}
                </option>
              ))}
            </select>
          </div>
          <div className="filtri-select">
            <select name="province" onChange={handleFilterChange}>
              <option value="">Provincia</option>
              {dropdownData.provinces.map((province) => (
                <option key={province} value={province}>
                  {province}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div className="filtri-dx">
        <div className="input-title">Servizi</div>

        <div className="checkbox-grid">
          {sortedFields.map((field) => (
            <div className="form-style" key={field}>
              <label>
                <input
                  type="checkbox"
                  name={field}
                  checked={formData[field]}
                  onChange={handleChange}
                />
                {formatServiceName(field)}
              </label>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ShowPorts;
//ultima versione funzionante
