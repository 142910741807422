import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../services/AuthContext";
import { AiOutlineLogout } from "react-icons/ai";
import { FaRegEdit } from "react-icons/fa";
import { GrAddCircle } from "react-icons/gr";
import { AiOutlineDelete } from "react-icons/ai";
import "./styles/Home.css";

function Menu({ isOwner, isAdmin }) {
  const navigate = useNavigate();
  const { setLogin } = useContext(AuthContext);

  const onLogOut = () => {
    localStorage.removeItem("AuthToken");
    navigate("/entry");
    setLogin(false);
  };

  return (
    <div className="Menu">
      <div className="menu-icons">
        {isAdmin && (
          <>
            <button type="button" title="Aggiungi" className="menu-icon-button">
              <GrAddCircle style={{ color: "white", fontSize: "24px" }} />
            </button>
            <button type="button" title="Modifica" className="menu-icon-button">
              <FaRegEdit style={{ color: "white", fontSize: "24px" }} />
            </button>
            <button type="button" title="Elimina" className="menu-icon-button">
              <AiOutlineDelete style={{ color: "white", fontSize: "24px" }} />
            </button>
          </>
        )}
        {isOwner && !isAdmin && (
          <button
            type="button"
            title="Modifica dati porto"
            className="menu-icon-button"
          >
            <FaRegEdit style={{ color: "white", fontSize: "24px" }} />
          </button>
        )}
      </div>

      <div className="logo-container-menu">
        <div className="logo-menu"></div>
        <div className="titolo-menu">Ports and landings of Sardinia</div>
        <div className="logo-menu"></div>
      </div>

      <div className="logout">
        <button type="button" onClick={onLogOut} title="Logout">
          <AiOutlineLogout
            style={{
              color: "white",
              fontSize: "24px",
            }}
          />
        </button>
      </div>
    </div>
  );
}

export default Menu;
