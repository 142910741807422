import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";

import axios from "axios";
import { useState, useEffect } from "react";

import { AuthContext } from "./services/AuthContext";
import { PrivateRoute } from "./services/PrivateRoute";

import Entry from "./pages/Entry";
import Home from "./pages/Home";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import CookieBanner from "./CookieBanner";

import "./App.css";

function App() {
  const [login, setLogin] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (localStorage.getItem("AuthToken")) {
      checkAuth();
    } else {
      setLoading(false);
    }
  }, []);

  async function checkAuth() {
    let response = await axios.get(
      process.env.REACT_APP_SERVER_URL + "users/auth",
      {
        headers: {
          authToken: localStorage.getItem("AuthToken"),
        },
      }
    );

    if (response?.data?.error) {
      console.log(response.data.error);
    } else if (response?.data?.user) {
      setLogin(response?.data?.user);
    }

    setLoading(false);
  }

  if (loading) {
    return <></>;
  }

  return (
    <>
      <AuthContext.Provider value={{ login, setLogin }}>
        <Router>
          <ShowCookieBanner />
          <Routes>
            <Route path="/entry" element={<Entry />} />
            <Route path="/home" element={<PrivateRoute />}>
              <Route path="/home" element={<Home />} />
            </Route>
            <Route
              path="*"
              element={<Navigate to={login ? "/home" : "/entry"} />}
            />
          </Routes>
        </Router>
      </AuthContext.Provider>
      <ToastContainer />
    </>
  );
}

function ShowCookieBanner() {
  const location = useLocation();

  // Mostra il CookieBanner solo nella rotta "/entry"
  return location.pathname === "/entry" ? <CookieBanner /> : null;
}

export default App;
