import React, { useState, useEffect } from "react";
import ShowPorts from "./ShowPorts";
import PageMapVisitors from "./PageMapVisitors";
import AddPort from "./AddPort";
import PortDetails from "./PortDetails";
import "../styles/Home.css";
import axios from "axios";

function Visitors(props) {
  const [selectedServices, setSelectedServices] = useState([]);
  const [filters, setFilters] = useState({
    city: "",
    province: "",
    category: "",
  });
  const [error, setError] = useState(null);
  const [selectedPort, setSelectedPort] = useState(null);
  const [isOwner, setIsOwner] = useState(false);
  const [hasRated, setHasRated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  const handleBackToMap = () => {
    setSelectedPort(null);
    setIsOwner(false);
    setHasRated(false);
  };

  useEffect(() => {
    const checkOwnershipAndRating = async () => {
      if (selectedPort) {
        try {
          const ownerResponse = await axios.get(
            `${process.env.REACT_APP_SERVER_URL}ports/${selectedPort.id}/isOwner`,
            {
              headers: { authToken: localStorage.getItem("AuthToken") },
            }
          );
          setIsOwner(ownerResponse.data.isOwner);
          const ratingResponse = await axios.get(
            `${process.env.REACT_APP_SERVER_URL}portRating/${selectedPort.id}/user`,
            {
              headers: { authToken: localStorage.getItem("AuthToken") },
            }
          );
          setHasRated(ratingResponse.data.hasRated);
        } catch (error) {
          console.error(
            "Errore nel controllo della proprietà o del feedback:",
            error
          );
        }
      }
    };

    checkOwnershipAndRating();
  }, [selectedPort]);

  useEffect(() => {
    const checkAdmin = async () => {
      try {
        const adminResponse = await axios.get(
          `${process.env.REACT_APP_SERVER_URL}users/admin`,
          {
            headers: { authToken: localStorage.getItem("AuthToken") },
          }
        );
        setIsAdmin(adminResponse.data.isAdmin);
      } catch (error) {
        console.error("Errore nel controllo dello stato admin:", error);
        setError("Errore nel controllo dello stato admin."); // Imposta il messaggio di errore
      }
    };

    checkAdmin();
  }, []);

  return (
    <div className="inner-page">
      <div className="inner-sx">
        <div className="page-container">
          <div className="top1">Welcome {props.name}</div>
          {isOwner ? (
            <div className="add-port-section">
              <h3>Gestisci il tuo porto</h3>
              <AddPort />
            </div>
          ) : (
            <>
              <ShowPorts
                setSelectedServices={setSelectedServices}
                setFilters={setFilters}
              />
              {selectedPort ? (
                <PortDetails
                  port={selectedPort}
                  onBack={handleBackToMap}
                  isOwner={isOwner}
                  hasRated={hasRated}
                />
              ) : (
                <div className="dati-container-seleziona">
                  Seleziona un porto dalla mappa
                </div>
              )}
            </>
          )}
        </div>
      </div>

      <div className="inner-dx">
        <PageMapVisitors
          selectedServices={selectedServices}
          filters={filters}
          onPortSelect={setSelectedPort}
        />
      </div>
    </div>
  );
}

export default Visitors;
