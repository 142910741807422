import React, { useState, useEffect } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCloudRain,
  faTemperatureHigh,
  faWind,
  faCloudSun,
  faCloud,
  faEye,
  faGauge,
  faSun,
  faBolt,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";

const formatHeader = (key) => {
  return key
    .replace(/([A-Z])/g, " $1")
    .replace(/^./, (str) => str.toUpperCase());
};
const formatDateTimeToItalian = (dateTimeString) => {
  const date = new Date(dateTimeString);

  const dateFormatter = new Intl.DateTimeFormat("it-IT", {
    dateStyle: "short",
  });
  const timeFormatter = new Intl.DateTimeFormat("it-IT", {
    timeStyle: "short",
  });

  const formattedDate = dateFormatter.format(date);
  const formattedTime = timeFormatter.format(date);

  return `${formattedDate} alle ${formattedTime}`;
};

const convertToDMS = (decimal) => {
  const degrees = Math.floor(decimal);
  const minutesFloat = (decimal - degrees) * 60;
  const minutes = Math.floor(minutesFloat);
  const seconds = ((minutesFloat - minutes) * 60).toFixed(0);

  return `${degrees}° ${minutes}' ${seconds}"`;
};

const convertDegreesToCardinal = (degrees) => {
  const cardinalDirections = [
    "N",
    "NNE",
    "NE",
    "ENE",
    "E",
    "ESE",
    "SE",
    "SSE",
    "S",
    "SSW",
    "SW",
    "WSW",
    "W",
    "WNW",
    "NW",
    "NNW",
  ];
  const normalizedDegrees = ((degrees % 360) + 360) % 360;
  const index = Math.round(normalizedDegrees / 22.5) % 16;
  return cardinalDirections[index];
};

const getWeatherIcon = (precipitation, cloudCover) => {
  if (precipitation > 10) {
    return <FontAwesomeIcon icon={faBolt} style={{ color: "#ffcc00" }} />;
  } else if (precipitation > 5) {
    return <FontAwesomeIcon icon={faCloudRain} style={{ color: "#3498db" }} />;
  } else if (precipitation > 2) {
    return <FontAwesomeIcon icon={faCloudRain} style={{ color: "#85c1e9" }} />;
  } else if (precipitation > 0) {
    return <FontAwesomeIcon icon={faCloudRain} style={{ color: "#a9cce3" }} />;
  } else if (cloudCover <= 25) {
    return <FontAwesomeIcon icon={faSun} style={{ color: "#f9d71c" }} />;
  } else if (cloudCover <= 50) {
    return <FontAwesomeIcon icon={faCloudSun} style={{ color: "#d1d8e0" }} />;
  } else {
    return <FontAwesomeIcon icon={faCloud} style={{ color: "#95a5a6" }} />;
  }
};

const PortDetails = ({ port, onBack, isOwner, hasRated }) => {
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);
  const [weatherData, setWeatherData] = useState(null);

  useEffect(() => {
    if (!port) return;

    const fetchWeatherData = async () => {
      try {
        const response = await axios.get(
          "https://api.weatherapi.com/v1/current.json",
          {
            params: {
              key: "be403994cdb3423dbec71046242711",
              q: `${port.latitudine},${port.longitudine}`,
            },
          }
        );

        const data = response.data;
        setWeatherData({
          lastUpdated: data.current.last_updated,
          precipitation: data.current.precip_mm,
          airTemperature: data.current.temp_c,
          pressure: data.current.pressure_mb,
          windSpeed: data.current.wind_kph,
          windDirection: data.current.wind_degree,
          cloudCover: data.current.cloud,
        });
      } catch (error) {
        console.error("Errore durante il recupero dei dati meteo:", error);
      }
    };

    fetchWeatherData();
  }, [port]);

  console.log(port);
  if (!port || !port.latitudine || !port.longitudine) {
    return (
      <div className="dati-container-seleziona">
        Seleziona un porto dalla mappa
      </div>
    );
  }

  const excludedFields = [
    "id",
    "createdAt",
    "updatedAt",
    "userId",
    "nomePorto",
  ];

  const handleRatingSubmit = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_SERVER_URL}portRating`,
        { portId: port.id, rating },
        { headers: { authToken: localStorage.getItem("AuthToken") } }
      );
      alert("Grazie per il tuo feedback!");
    } catch (error) {
      console.error("Errore nell'invio del feedback:", error);
    }
  };

  if (!port) {
    return (
      <div className="dati-container-seleziona">
        Seleziona un porto dalla mappa
      </div>
    );
  }

  return (
    <div className="dati-container">
      <div className="input-title-dati">{port.nomePorto}</div>
      {weatherData && (
        <div className="weather-section">
          <div className="weather-section-dati-titolo">
            Meteo del {formatDateTimeToItalian(weatherData.lastUpdated)}
          </div>
          <div className="weather-section-dati">
            {getWeatherIcon(weatherData.precipitation, weatherData.cloudCover)}{" "}
            {weatherData.airTemperature} °C
          </div>
          <div className="weather-section-dati">
            <FontAwesomeIcon icon={faGauge} /> {weatherData.pressure} hPa
          </div>
          <div className="weather-section-dati">
            <FontAwesomeIcon icon={faWind} />{" "}
            {convertDegreesToCardinal(weatherData.windDirection)}{" "}
            {weatherData.windSpeed} km/h
          </div>
        </div>
      )}
      <div className="middle-bottom-container">
        <div className="middle-bottom-dati">
          <div className="dati-generali">
            <div className="descr-dati">Dati generali</div>
            {Object.entries(port)
              .filter(
                ([key, value]) =>
                  !excludedFields.includes(key) && typeof value !== "boolean"
              )
              .map(([key, value]) => (
                <div key={key} className="grid-item">
                  <strong>{formatHeader(key)}</strong>
                  {key === "latitudine" || key === "longitudine" ? (
                    <>: {convertToDMS(value)}</>
                  ) : (
                    <>: {value}</>
                  )}
                </div>
              ))}
          </div>

          <div className="servizi">
            <div className="descr-dati">Servizi</div>
            {Object.entries(port)
              .filter(
                ([key, value]) =>
                  !excludedFields.includes(key) && typeof value === "boolean"
              )
              .map(([key, value]) => (
                <div key={key} className="grid-item">
                  <strong>{formatHeader(key)}</strong>
                </div>
              ))}
          </div>
        </div>
        <div className="bottom">
          <button onClick={onBack}>Chiudi</button>
        </div>
      </div>
      {!hasRated && (
        <div className="feedback-section">
          <h3>Lascia un feedback per {port.nomePorto}</h3>
          <div className="stars">
            {[1, 2, 3, 4, 5].map((star) => (
              <span
                key={star}
                style={{
                  fontSize: "1.5rem",
                  color: star <= (hover || rating) ? "#ffc107" : "#e4e5e9",
                }}
                onClick={() => setRating(star)}
                onMouseEnter={() => setHover(star)}
                onMouseLeave={() => setHover(0)}
              >
                ★
              </span>
            ))}
          </div>
          <div className="bottom">
            <button onClick={handleRatingSubmit}>Invia Feedback</button>
          </div>
        </div>
      )}
      ;
    </div>
  );
};

export default PortDetails;
//ultima versione funzionante
