export const validateForm = (formData) => {
  const errors = [];

  // Campi obbligatori
  const requiredFields = [
    "*Category",
    "*Port Name",
    "*Latitude",
    "*Longitude",
    "*email",
  ];
  requiredFields.forEach((field) => {
    if (!formData[field]) {
      errors.push(`${field} è obbligatorio.`);
    }
  });

  // Verifica email
  if (formData.email && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
    errors.push("L'indirizzo email non è valido.");
  }

  return errors;
};
