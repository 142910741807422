import React, { useState } from "react";
import "../styles/AddPort.css";
import { validateForm } from "../../services/ValidationForm";
import { toast } from "react-toastify";

function AddPort() {
  const [formData, setFormData] = useState({
    categoria: "",
    nomePorto: "",
    latitudine: "",
    longitudine: "",
    email: "",
    tel: "",
    vhf: "",
    città: "",
    cap: "",
    provincia: "",
    nazione: "",
    sitoWeb: "",
    indirizzo: "",
    numeriUtili: "",
    pescaggioMinimo: "",
    pescaggioMassimo: "",
    fondali: "",
    ridosso: "",
    posti: "",
    orario: "",
    lunghezzaMaxBarche: "",
    pericoli: "",
    traversie: "",
    carburante: false,
    acqua: false,
    energiaElettrica: false,
    gru: false,
    serviziIgienici: false,
    docce: false,
    serviziAntincendio: false,
    riparazioneMotori: false,
    riparazioneElettriche: false,
    serviziMeteo: false,
    ormeggiatori: false,
    guardiania: false,
    wifi: false,
    bar: false,
    ristorante: false,
    pizzeria: false,
    market: false,
    noleggioAuto: false,
    noleggioBici: false,
    scivolo: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validazione del form
    const errors = validateForm(formData);
    if (errors.length > 0) {
      // Mostra gli errori con Toast
      errors.forEach((error) => toast.error(error));
      return; // Blocca l'invio del form
    }

    // Se non ci sono errori, invia i dati
    console.log("Form inviato con successo", formData);
    toast.success("Dati inviati con successo!");
  };
  return (
    <div className="addPort-container">
      <form onSubmit={handleSubmit}>
        <div className="middle">
          <div className="input-title">General data</div>{" "}
          <div className="middle-top">
            <div className="middle-top-sx">
              {" "}
              {[
                "*Category",
                "*Port Name",
                "*Latitude",
                "*Longitude",
                "*email",
                "Phone",
                "VHF",
                "City",
                "Postal Code",
                "Province",
                "Country",
                "Website",
              ].map((field) => (
                <div className="form-style">
                  <div className="form-style1" key={field}>
                    <label>{field}</label>
                    <input
                      type={field === "email" ? "email" : "text"}
                      name={field}
                      value={formData[field]}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              ))}
            </div>

            <div className="middle-top-dx">
              {" "}
              {[
                "Address",
                "Useful Numbers",
                "Min. Draft",
                "Max. Draft",
                "Seabed",
                "Shelter",
                "Berths",
                "Schedule",
                "Max Length",
                "Dangers",
                "Adverse Cond.",
              ].map((field) => (
                <div className="form-style">
                  <div className="form-style1" key={field}>
                    <label>{field}</label>
                    <input
                      type="text"
                      name={field}
                      value={formData[field]}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="input-title"> Services</div>
          <div className="middle-bottom">
            <div className="middle-bottom-sx">
              {" "}
              {["Fuel", "Water", "Electricity", "Crane", "Restrooms"].map(
                (field) => (
                  <div className="form-style" key={field}>
                    <label>
                      <input
                        type="checkbox"
                        name={field}
                        checked={formData[field]}
                        onChange={handleChange}
                      />
                      {field}
                    </label>
                  </div>
                )
              )}
            </div>

            <div className="middle-bottom-dx">
              {" "}
              {[
                "Showers",
                "Fire-Fighting Services",
                "Engine Repairs",
                "Electrical Repairs",
                "Weather Services",
              ].map((field) => (
                <div className="form-style" key={field}>
                  <label>
                    <input
                      type="checkbox"
                      name={field}
                      checked={formData[field]}
                      onChange={handleChange}
                    />
                    {field}
                  </label>
                </div>
              ))}
            </div>

            <div className="middle-bottom-sx">
              {" "}
              {["Dockhands", "Security", "Wi-Fi", "Bar", "Restaurant"].map(
                (field) => (
                  <div className="form-style" key={field}>
                    <label>
                      <input
                        type="checkbox"
                        name={field}
                        checked={formData[field]}
                        onChange={handleChange}
                      />
                      {field}
                    </label>
                  </div>
                )
              )}
            </div>

            <div className="middle-bottom-dx">
              {" "}
              {[
                "Pizzeria",
                "Market",
                "Car Rental",
                "Bike Rental",
                "Slipway",
              ].map((field) => (
                <div className="form-style" key={field}>
                  <label>
                    <input
                      type="checkbox"
                      name={field}
                      checked={formData[field]}
                      onChange={handleChange}
                    />
                    {field}
                  </label>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="bottom">
          <button type="submit">Submit</button>
        </div>
      </form>
    </div>
  );
}

export default AddPort;
