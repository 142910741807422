import React, { useContext, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../services/AuthContext";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import Validation from "../../services/Validation";
import { toast } from "react-toastify";

function Login(props) {
  const navigate = useNavigate();
  const { setLogin } = useContext(AuthContext);
  const [passwordVisible, setPasswordVisible] = useState(false);

  async function onLogin(e) {
    e.preventDefault();

    if (Validation.isEmail(e.target[0].value)) {
      // console.log("EMAIL", e.target[0].value);
    }

    try {
      let response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}users/login`,
        {
          ...(Validation.isEmail(e.target[0].value)
            ? { email: e.target[0].value }
            : { username: e.target[0].value }),
          password: e.target[1].value,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (response?.data?.error) {
        toast.error("Utente non esistente!!!", {
          onClose: () => {
            window.location.reload();
          },
          autoClose: 3000,
        });
      } else if (response?.data?.status) {
        const { name, username, email, status, portOwner } = response.data;

        setLogin({
          name,
          username,
          email,
          status,
          portOwner,
        });

        localStorage.setItem("AuthToken", response?.data?.authToken);

        if (status === "admin") {
          navigate("/home", { state: { view: "administrator" } });
        } else if (status === "attivo" && portOwner) {
          navigate("/home", {
            state: { name, username, email, view: "portOwners" },
          });
        } else if (status === "attivo" && !portOwner) {
          navigate("/home", {
            state: { name, username, email, view: "visitors" },
          });
        } else {
          console.error("Invalid user status");
        }
      }
    } catch (error) {
      console.error("Network Error:", error);
      toast.error("Errore di rete. Riprova più tardi.");
    }
  }

  return (
    <div className="content-wrapper">
      <div className="logo-container">
        <div className="logo"></div>
        <div className="titolo">
          <div className="titolo">Sardinian ports</div>
          <div className="titolo">and landings</div>
        </div>
      </div>

      <div className="form-container">
        <form onSubmit={onLogin}>
          <input type="text" placeholder="Email or Username" required />
          <div className="password-wrapper">
            <input
              type={passwordVisible ? "text" : "password"}
              placeholder="Password"
              required
            />
            <span
              className="eye-icon"
              onClick={() => setPasswordVisible(!passwordVisible)}
            >
              {passwordVisible ? <FaRegEyeSlash /> : <FaRegEye />}
            </span>
          </div>
          <button type="submit">Login</button>
          <a href="#" className="forgot-password">
            Forgot Password
          </a>
        </form>
      </div>

      <div className="links-container">
        <a href="#" onClick={() => props.changeToSignUp()} className="signup">
          Sign Up
        </a>
      </div>
    </div>
  );
}

export default Login;
